import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import SimpleCard from "@gull/components/cards/SimpleCard";

const ViewApplication = () => {
    const { id } = useParams();
    const [education, setEducation] = useState([]);
    const [employment, setEmployment] = useState([]);
    const [resume, setResume] = useState([]);
    const [personalstatement, setPersonalStatement] = useState([]);
    const [referenceletter, setReferenceLetter] = useState([]);
    const [referenceletterdata, setReferenceLetterData] = useState([]);
    const [otherevaluations, setOtherEvaluations] = useState([]);

    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [evaluationId, setEvaluationId] = useState("");

    
    const [data, setData] = useState({
        creativity: 0,
        expression: 0,
        past_ambition: 0,
        future_ambition: 0,
        relevance: 0,
        totalScore: 0,
        next_stage: null, // Store accept/reject as boolean

      });
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    const userdata = JSON.parse(localStorage.getItem("auth_user_info"));
    const loggedInUserId= userdata.id
    const username= userdata.username

    console.log("loggedInUserId",loggedInUserId)


    console.log("token",token)
    // const auth = JSON.parse(localStorage.getItem("auth_user_info"));

    // const applicantID = auth ? auth.id : null;

     // Handle input changes and update state


     useEffect(() => {
      const fetchEvaluations = async () => {
          try {
              const response = await fetch(
                  `${process.env.REACT_APP_BASE_URL_strapi}/evaluations?populate=application&populate=users_permissions_user&filters[application][id][$eq]=${id}`,
                  {
                      method: 'GET',
                      headers: {
                          'Authorization': `Bearer ${token}`, // JWT token of the logged-in user
                      },
                  }
              );
  
              const data = await response.json();
              console.log('All evaluations for the application:', data);
  
              if (data?.data?.length) {
                  // Filter out the evaluation with userId 53
                  const filteredEvaluations = data.data.filter(
                      (evaluation) => evaluation.attributes.users_permissions_user.data.id !== loggedInUserId
                  );
  
                  // Update state with the filtered evaluations
                  setOtherEvaluations(
                      filteredEvaluations.map((evaluation) => ({
                          ...evaluation.attributes,
                          totalScore:
                              evaluation.attributes.creativity +
                              evaluation.attributes.expression +
                              evaluation.attributes.past_ambition +
                              evaluation.attributes.future_ambition +
                              evaluation.attributes.relevance,
                      }))
                  );
                  console.log("evaluations",otherevaluations)
              } else {
                  console.log('No evaluations found for this application.');
              }
          } catch (error) {
              console.error('Error fetching evaluations:', error);
          }
      };
  
      fetchEvaluations();
  }, [id, token]);

     useEffect(() => {
        const fetchData = async () => {
            fetch(`${process.env.REACT_APP_BASE_URL_strapi}/users/me?populate[evaluations][filters][application][id][$eq]=${id}`, { 
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}` // JWT token of the logged-in user
                }
              })
                .then(response => response.json())
                .then(evaluation => {
                  console.log('User with filtered evaluations:', evaluation);
              
                  const evaluations = evaluation.evaluations[0]; // Extract the filtered evaluations from the populated response
                  if (evaluations) {
                    evaluations.totalScore =
                    evaluations.creativity +
                    evaluations.expression +
                    evaluations.past_ambition +
                    evaluations.future_ambition +
                    evaluations.relevance;
                    setData(evaluations)
                    setEvaluationId(evaluations.id); // Store the evaluation ID in the state for future updates

                    // Assuming evaluations are returned and are related to applications
                 
                  } else {
                    console.log('No evaluations related to the specified application found for the user');
                  }
                })
                .catch(error => {
                  console.error('Error fetching user and filtered evaluations:', error);
                });
              
        };
            // Fetch the latest campaign first
    
    
        fetchData();
      }, []);




      const handleInputChange = (event, nextStageValue) => {
        const { name, type } = event.target;
      
        if (type === "radio") {
          setData((prevData) => ({
            ...prevData,
            [name]: nextStageValue, // Update based on the selected radio button
          }));
        } else {
          // Handle other input types (e.g., number inputs)
          const { value } = event.target;
          const parsedValue = value === "" ? 0 : parseInt(value, 10);
      
          if (parsedValue > 10) {
            alert("Score cannot be more than 10");
            return;
          }
      
          setData((prevData) => {
            const newData = {
              ...prevData,
              [name]: isNaN(parsedValue) ? 0 : parsedValue,
            };
      
            const totalScore =
              newData.creativity +
              newData.expression +
              newData.past_ambition +
              newData.future_ambition +
              newData.relevance;
      
            return {
              ...newData,
              totalScore, // Update the total score
            };
          });
        }
      };
      
      
const handleButtonClick1 = async (event) => {
    if (!id || !token) {
      alert("Application ID or Token missing!");
      return;
    }
  
    try {
      // Check if an evaluation ID exists (from the useEffect)
      if (evaluationId) {
        // If evaluation ID exists, update the existing evaluation
        const updateResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations/${evaluationId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
          body: JSON.stringify({
            data: {
              creativity: data.creativity,
              expression: data.expression,
              past_ambition: data.past_ambition,
              future_ambition: data.future_ambition,
              relevance: data.relevance,
              totalScore: data.totalScore,
              next_stage: data.next_stage,
              application: id, // Send the application ID
            },
          }),
        });
  
        if (updateResponse.ok) {
            const responseData = await updateResponse.json();
            console.log("Evaluation created successfully:", responseData);
            alert("Evaluation Updated successfully!");
            setTimeout(() => {
              window.history.back(); // Redirects to the previous page
            }, 100); // Slight delay to ensure alert displays properly
      
            // After creating the evaluation, fetch all evaluations related to the application
            const fetchEvaluationsResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations?filters[application][id][$eq]=${id}&populate=*`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token for authentication
              },
            });
      
            if (fetchEvaluationsResponse.ok) {
              const evaluationsData = await fetchEvaluationsResponse.json();
              console.log("All evaluations fetched successfully:", evaluationsData);
             
              // Map through the evaluations and count the number of next_stage true/false
                const result = evaluationsData.data.reduce(
                    (acc, val) => {
                    if (val.attributes.next_stage == true) {
                        acc.trueCount++;
                    } else if(val.attributes.next_stage == false) {
                        acc.falseCount++;
                    }else{
                      
                    }
                    return acc;
                    },
                    { trueCount: 0, falseCount: 0 }
                );
                
                // Check the conditions and return the appropriate value
                let finalDecision = "none"; // Default value
                if (result.trueCount >= 2) {
                    let  outgoingData = {
                        status:'approved'
                      }           
                     try {
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
                          method: 'PUT', 
                          headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json', // Ensure content-type is set to JSON
                          },
                          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                        });
                    
                        if (!response.ok) {
                          throw new Error('Failed to submit form');
                        }
                    
                        const data = await response.json();
                        console.log(data);
                      } catch (error) {
                        console.error('Error:', error);
                      }



                    finalDecision = "yes";
                } else if (result.falseCount >= 2) {
                  let  outgoingData = {
                    status:'rejected'
                  }
                    try {
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
                          method: 'PUT', 
                          headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json', // Ensure content-type is set to JSON
                          },
                          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                        });
                    
                        if (!response.ok) {
                          throw new Error('Failed to submit form');
                        }
                    
                        const data = await response.json();
                        console.log(data);
                      } catch (error) {
                        console.error('Error:', error);
                      }

                    finalDecision = "no";
                }
                
                console.log("Final decision:", finalDecision);
              // Handle the evaluations data (for example, update the state or display the data)
              // You can set the data in a state or manipulate it as needed
      
            } else {
              console.error("Failed to fetch evaluations:", fetchEvaluationsResponse);
              alert("Failed to fetch evaluations. Please try again.");
            }
          } else {
            console.error("Failed to create evaluation:", updateResponse);
            alert("Failed to create evaluation. Please try again.");
          }
      } else {
        // If no evaluation ID, create a new evaluation
        const createResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
          body: JSON.stringify({
            data: {
              creativity: data.creativity,
              expression: data.expression,
              past_ambition: data.past_ambition,
              future_ambition: data.future_ambition,
              relevance: data.relevance,
              totalScore: data.totalScore,
              next_stage: data.next_stage,
              application: id, // Send the application ID
            },
          }),
        });
  
        if (createResponse.ok) {
            const responseData = await createResponse.json();
            console.log("Evaluation created successfully:", responseData);
            alert("Evaluation created successfully!");
            setTimeout(() => {
              window.history.back(); // Redirects to the previous page
            }, 100); // Slight delay to ensure alert displays properly
      
            // After creating the evaluation, fetch all evaluations related to the application
            const fetchEvaluationsResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations?filters[application][id][$eq]=${id}&populate=*`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token for authentication
              },
            });
      
            if (fetchEvaluationsResponse.ok) {

              const evaluationsData = await fetchEvaluationsResponse.json();
              console.log("All evaluations fetched successfully:", evaluationsData);
      
              // Handle the evaluations data (for example, update the state or display the data)
              // You can set the data in a state or manipulate it as needed
                  
              // Map through the evaluations and count the number of next_stage true/false
              const result = evaluationsData.data.reduce(
                (acc, val) => {
                if (val.attributes.next_stage == true) {
                    acc.trueCount++;
                } else if(val.attributes.next_stage == false) {
                    acc.falseCount++;
                }else{
                  
                }
                return acc;
                },
                { trueCount: 0, falseCount: 0 }
            );
            
            // Check the conditions and return the appropriate value
            let finalDecision = "none"; // Default value
            if (result.trueCount >= 2) {
                let  outgoingData = {
                    status:'approved'
                  }           
                 try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
                      method: 'PUT', 
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json', // Ensure content-type is set to JSON
                      },
                      body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                    });
                
                    if (!response.ok) {
                      throw new Error('Failed to submit form');
                    }
                
                    const data = await response.json();
                    console.log(data);
                  } catch (error) {
                    console.error('Error:', error);
                  }



                finalDecision = "yes";
            } else if (result.falseCount >= 2) {
              let  outgoingData = {
                status:'rejected'
              }
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
                      method: 'PUT', 
                      headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json', // Ensure content-type is set to JSON
                      },
                      body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                    });
                
                    if (!response.ok) {
                      throw new Error('Failed to submit form');
                    }
                
                    const data = await response.json();
                    console.log(data);
                  } catch (error) {
                    console.error('Error:', error);
                  }

                finalDecision = "no";
            }
            
            console.log("Final decision:", finalDecision);
          // Handle the evaluations data (for example, update the state or display the data)
          // You can set the data in a state or manipulate it as needed
            
      
            } else {
              console.error("Failed to fetch evaluations:", fetchEvaluationsResponse);
              alert("Failed to fetch evaluations. Please try again.");
            }
          } else {
            console.error("Failed to create evaluation:", createResponse);
            alert("Failed to create evaluation. Please try again.");
          }
      }
    } catch (error) {
      console.error("Error saving evaluation:", error);
      alert("An error occurred while saving the evaluation.");
    }
  };
  
  
    const fetchData = async () => {
        try {
       
            setLoading(false);
            const response1 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}?populate[academic_detailss]=*&populate[personal_statement]=*&populate[privacy_policy_statement]=*&populate[professional_detailss]=*&populate[reference_letter][populate][reference_letter]=*&populate[resume][populate][resume]=*
            `, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Your JWT token
                    'Content-Type': 'application/json'
                }
            });
            const data1 = await response1.json();
            console.log("hope",data1?.data?.attributes?.academic_detailss?.data);
            setResume({url:data1?.data?.attributes?.resume?.data?.attributes?.resume?.data?.attributes?.url})
            
         console.log("data1?.data?.attributes?.reference_letter",data1?.data?.attributes?.reference_letter?.data)
              setPersonalStatement(data1?.data?.attributes?.personal_statement?.data?.attributes)
              setFields(data1?.data?.attributes);
              setEducation(data1?.data?.attributes?.academic_detailss?.data);
              setReferenceLetter({url:data1?.data?.attributes?.reference_letter?.data?.attributes?.reference_letter?.data?.attributes?.url})
              setReferenceLetterData(data1?.data?.attributes?.reference_letter?.data?.attributes)
              setEmployment(data1?.data?.attributes?.professional_detailss?.data);

        setTimeout(() => {
            console.log("fields",fields);
            console.log("education",education);
            console.log("resume",resume);

            
        }, 4000);
        } catch (error) {
            console.error('Error fetching personal information data:', error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const handleButtonClick = async (event) => {
        const eventName = event.target.innerText.toLowerCase();

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/score`, {
            method: 'PUT',
            headers: {
                'Accept': '*/*',
                'Event': eventName,
                'SessionKey': '123',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicantId: parseInt(id),
                graceScore: parseInt(fields.graceScore),
                score: parseInt(fields.score)
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        fetchData();
    }




    // Email and contact
    let emails = fields && fields.email ? fields.email.split(', ') : ['N/A'];
    let contacts = fields && fields.contact ? fields.contact.split(', ') : ['N/A', 'N/A', 'N/A'];

    // Remove 'L:' from the landline number
    if (contacts[2]) {
        contacts[2] = contacts[2].replace('L:', '');
    }
    // Text Formatting
    function formatText(text) {
        let lines = text.split('\n');
        let formattedText = lines.map(line => `<p>${line}</p>`).join('');
        return formattedText;
    }

    let coverLettertext = fields && fields.coverLetter ? formatText(fields.coverLetter) : 'N/A';
    let formattedCoverLetter = formatText(coverLettertext);

    let skillsText = fields && fields.skills ? fields.skills : 'N/A';
    let skillsTextFormatted = formatText(skillsText);

    // let fundingText = fields && fields.fundingRequest ? fields.fundingRequest : 'N/A';
    // let fundingTextFormatted = formatText(fundingText);

    let fundingStatus = fields && fields.fundingRequest
        ? (fields.fundingRequest === "1" ? "Funding is required" : "Funding is not required")
        : "No fields object or funding request field";

    // Styles:
    const styles = {
        display: 'inline-block',
        maxWidth: '100px',
        fontWeight: 700,
        height: '40px',
        fontSize: '12pt',
        lineHeight: 1.2,
    };
    let displayStyle = { display: styles.display };
    return (
        <div className="app-admin-wrap layout-sidebar-large">
            <DashboardHeader />
            <AdminNav />
            <div className="main-content-wrap d-flex flex-column sidenav-open">
                {
                    loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                        </div>

                    ) : (
                        <div className="mb-4">
                            <h1>{message}</h1>
                            <SimpleCard title={`EVALUATION`} className="mb-4">
                            <Row className="justify-content-center">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                {/* Input fields for evaluation scores */}
                                <div className="row justify-content-between mb-1">
                                <h5  className="text-capitalize mb-1 col-12 text-end" >
                                  {username}
                                </h5>
                            
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Creativity Score:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="creativity"
                                    value={data.creativity || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Expression Score:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="expression"
                                    value={data.expression || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Past Ambition:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="past_ambition"
                                    value={data.past_ambition || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Future Ambition:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="future_ambition"
                                    value={data.future_ambition || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-2">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Relevance:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    name="relevance"
                                    value={data.relevance || 0}
                                    onChange={handleInputChange}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    />
                                </div>

                                <div className="row justify-content-between mb-4">
                                    <h5 style={displayStyle} className="text-capitalize mb-1 col-6">
                                    Total Score:
                                    </h5>
                                    <input
                                    style={styles}
                                    type="number"
                                    value={data.totalScore}
                                    className="form-control col-6"
                                    disabled
                                    />
                                </div>

                                {/* Checkbox for Accept/Reject */}
                                <div className="row mb-4 align-items-center">
                                <h5
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    marginBottom: '0',
                                  }}
                                  className="text-capitalize mb-1 col-6"
                                >
                                  Accept/Reject:
                                </h5>
                                <div className="col-6 d-flex flex-column align-items-end">
                                  {/* Accept Radio Button */}
                                  <label className="form-check-label mb-2" style={{paddingRight:"16px"}}>
                                    <input
                                      type="radio"
                                      name="next_stage"
                                      value="accept"
                                      checked={data.next_stage === true} // Check if `next_stage` is true
                                      onChange={(e) => handleInputChange(e, true)} // Pass true for "Accept"
                                      className="form-check-input"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                    Accept
                                  </label>

                                  {/* Reject Radio Button */}
                                  <label className="form-check-label" style={{paddingRight:"20px"}}>
                                    <input
                                      type="radio"
                                      name="next_stage"
                                      value="reject"
                                      checked={data.next_stage === false} // Check if `next_stage` is false
                                      onChange={(e) => handleInputChange(e, false)} // Pass false for "Reject"
                                      className="form-check-input"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                    Reject
                                  </label>
                                </div>
                              </div>


                                {/* Buttons for actions */}
                                <div className="text-center">
                                    {/* <button
                                    style={styles}
                                    type="button"
                                    className="m-1 text-capitalize btn btn-success text-white"
                                    onClick={handleButtonClick1}
                                    >
                                    Select
                                    </button> */}
                                    <button
                                    style={styles}
                                    type="button"
                                    className="m-1 text-capitalize btn btn-outline-primary"
                                    onClick={handleButtonClick1}
                                    disabled={data.next_stage === null} // Disable button if next_stage is null
                                    >
                                    Save
                                    
                                    </button>
                                    {/* <button
                                    style={styles}
                                    type="button"
                                    className="m-1 text-capitalize btn btn-danger text-white"
                                    onClick={handleButtonClick1}
                                    >
                                    Reject
                                    </button> */}
                                </div>
                                </Col>
                                {otherevaluations.map((evaluation, index) => (
                                  <Col 
  lg={2} 
  md={3} 
  sm={3} 
  xs={12} 
  key={index} 
  style={{ paddingLeft: "55px" }}
>          {/* Admin Username */}
          <div className="text-right font-weight-bold mb-2">
            <h5>
           {evaluation.users_permissions_user?.data?.attributes?.username || "Unknown Admin"} 
           </h5>
          </div>

          {/* Input fields for evaluation scores */}
                                <div className="row justify-content-end mb-2">
                                 
                                    <input
                                    style={styles}
                                    type="number"
                                    name="creativity"
                                    value={evaluation.creativity || 0}
                                    className="form-control col-6"
                                    min="0"
                                    max="10"
                                    disabled
                                    />
                                </div>
        
                             <div className="row justify-content-end mb-2">
                                 
                                 <input
                                 style={styles}
                                 type="number"
                                 name="expression"
                                 value={evaluation.expression || 0}
                                 className="form-control col-6"
                                 min="0"
                                 max="10"
                                 disabled
                                 />
                             </div>
        
                           <div className="row justify-content-end mb-2">
                                 
                                 <input
                                 style={styles}
                                 type="number"
                                 name="past_ambition"
                                 value={evaluation.past_ambition || 0}
                                 className="form-control col-6"
                                 min="0"
                                 max="10"
                                 disabled
                                 />
                             </div>
        
                             <div className="row justify-content-end mb-2">
                                 
                                 <input
                                 style={styles}
                                 type="number"
                                 name="future_ambition"
                                 value={evaluation.future_ambition || 0}
                                 className="form-control col-6"
                                 min="0"
                                 max="10"
                                 disabled
                                 />
                             </div>
          <div className="row justify-content-end mb-2">
            <input
            style={styles}
              type="number"
              name="relevance"
              value={evaluation.relevance || 0}
              className="form-control col-6"
              min="0"
              max="10"
              disabled
            />
          </div>
          <div className="row justify-content-end mb-4">
            <input
             style={styles}
              type="number"
              value={evaluation.totalScore || 0}
              className="form-control col-6"
              disabled
            />
          </div>

          {/* Checkbox for Accept/Reject */}
          <div className="row justify-content-end align-items-center mb-4">
            <div className="col-6 d-flex align-items-center justify-content-end">
          
              <span
                style={{
                  color: evaluation.next_stage ? "#28a745" : "#dc3545",
                  fontWeight: "bold",
                }}
              >
                {evaluation.next_stage ? "Accepted" : "Rejected"}
              </span>
            </div>
          </div>
        </Col>
      ))}
                            </Row>
                            </SimpleCard>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>

                                    <SimpleCard title={`${fields && fields.first_name ? fields.first_name : 'N/A'}`}>

                                        <Tabs defaultActiveKey="home" id="home">
                                            <Tab eventKey="home" title="BASIC INFROMATION">
                                                <div className="row">
                                                    <p className='col-6'><strong>Name:</strong> {fields && fields.first_name ? fields?.first_name : 'N/A'}</p>
                                                    <p className='col-6'><strong>Middle Name:</strong> {fields && fields.middle_name ? fields?.middle_name : 'N/A'}</p>
                                                    <p className='col-6'><strong>Last Name:</strong> {fields && fields.last_name ? fields?.last_name : 'N/A'}</p>

                                                    {/* <div className='col-6 row'> */}
                                                    <p className='col-6'><strong>Primary Email:</strong> {fields && fields.primary_email ? fields?.primary_email : 'N/A'}</p>
                                                    <p className='col-6'><strong>Secondary Email:</strong> {fields && fields.secondary_email ? fields?.secondary_email : 'N/A'}</p>
                                                    {/* </div> */}
                                                    {/* <div className='col-6 row'> */}
                                                    <p className="col-6"><strong>Disability:</strong> {fields && fields.disability ? fields.disability : 'N/A'}</p>

                                                    <p className='col-6'><strong>Primary Contact:</strong>  {fields && fields.primary_phone_number ? fields.primary_phone_number : 'N/A'}</p>
                                                    <p className='col-6'><strong>Secondary Contact:</strong>  {fields && fields.secondary_phone_number ? fields.secondary_phone_number : 'N/A'}</p>
                                                    <p className='col-6'><strong>Landline:</strong>{fields && fields.landline ? fields.landline : 'N/A'}</p>
                                                    {/* </div> */}
                                                    <p className='col-6'><strong>Gender:</strong> {fields && fields.gender ? fields.gender : 'N/A'}</p>
                                                    <p className='col-6'><strong>Nationality:</strong> {fields && fields.nationality ? fields.nationality : 'N/A'}</p>
                                                   
                                                    <p className='col-6'><strong>Date of Birth:</strong> {fields && fields.date_of_birth ? fields.date_of_birth : 'N/A'}</p>
                                                    <p className='col-6'><strong>Address:</strong> {fields && fields.address ? fields.address : 'N/A'}</p>
                                                    <p className='col-6'><strong>Province:</strong> {fields && fields.province ? fields.province : 'N/A'}</p>
                                                    <p className='col-6'><strong>City:</strong> {fields && fields.city ? fields.city : 'N/A'}</p>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="academic" title="ACADEMIC">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Institute</th>
                                                            <th scope="col">Degree</th>
                                                            <th scope="col">Field Of Study</th>
                                                            <th scope="col">Start Year </th>
                                                            <th scope="col">End Year </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {education && education?.map((edu, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{edu?.attributes.institute || 'N/A'}</th>
                                                                <td>{edu?.attributes.degree || 'N/A'}</td>
                                                                <td>{edu?.attributes.field_of_study || 'N/A'}</td>
                                                                <td>{edu?.attributes.start_year || 'N/A'}</td>
                                                                <td>{edu?.attributes.end_year || 'N/A'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="profile" title="PROFESSIONAL">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Organization</th>
                                                            <th scope="col">Job</th>
                                                            <th scope="col">Type Of Job</th>

                                                            <th scope="col">Start Year </th>
                                                            <th scope="col">End Year </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {employment && employment.map((employ, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{employ?.attributes.organization_name || 'N/A'}</th>
                                                                <td>{employ?.attributes.job_title || 'N/A'}</td>
                                                                <td>{employ?.attributes.type_of_job || 'N/A'}</td>

                                                                <td>{employ?.attributes.start_year || 'N/A'}</td>
                                                                <td>{employ?.attributes.end_year || 'N/A'}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="resume" title="RESUME">
                                                <p><strong>Resume:</strong> <br />{resume && resume?.url ? 'Resume Attached' : 'N/A'}</p>

                                                <div>
                                                    {resume?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${resume?.url}`, '_blank')}>View</button>}
                                                </div>
                                            </Tab>
                                            <Tab eventKey="contact" title="PERSONAL STATEMENT">
                                                <p><strong>How did you hear about this fellowship opportunity?:</strong> <br />{personalstatement && personalstatement?.social_media ? personalstatement?.social_media : 'N/A'}</p>
                                               
                                                <p><strong>Personal Statement:</strong> <br />{personalstatement && personalstatement?.personal_statement ? personalstatement?.personal_statement : 'N/A'}</p>

                                                <p><strong>Skills:</strong> <br />{personalstatement && personalstatement?.skills ? personalstatement?.skills : 'N/A'}</p>
                                                <p><strong>Project Detail:</strong> <br />{personalstatement && personalstatement?.project_detail ? personalstatement?.project_detail : 'N/A'}</p>

                                            </Tab>
                                            <Tab eventKey="reference" title="REFERENCE LETTER">
                                            <p><strong>Name:</strong> <br />{referenceletterdata && referenceletterdata?.name ? referenceletterdata?.name : 'N/A'}</p>
                                            <p><strong>Organization Name:</strong> <br />{referenceletterdata && referenceletterdata?.organization_name ? referenceletterdata?.organization_name : 'N/A'}</p>
                                            <p><strong>Designation:</strong> <br />{referenceletterdata && referenceletterdata?.designation ? referenceletterdata?.designation : 'N/A'}</p>
                                            <p><strong>Contact Number:</strong> <br />{referenceletterdata && referenceletterdata?.contact_number ? referenceletterdata?.contact_number : 'N/A'}</p>
                                            <p><strong>Email:</strong> <br />{referenceletterdata && referenceletterdata?.email ? referenceletterdata?.email : 'N/A'}</p>

                                                <p><strong>Reference:</strong> <br />{referenceletter && referenceletter?.url ? 'Reference Letter Attached' : 'N/A'}</p>

                                                <div>
                                                    {referenceletter?.url && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${referenceletter?.url}`, '_blank')}>View</button>}
                                                </div>
                                            </Tab>
                                            <Tab eventKey="funding" title="FUNDING">
                                                <strong className='mb-3'>Funding Request: </strong>
                                                <p>
                                                {referenceletterdata?.scholarship
                                                  ? "Applying for Scholarship"
                                                  : "Not Applying for Scholarship"}
                                              </p>
                                              
                                             

                                            </Tab>
                                        </Tabs>
                                    </SimpleCard>
                                </Col>
                            </Row>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default ViewApplication;
