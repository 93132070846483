import React, { useState } from 'react';

// Search Component
const DashboardSearch = ({ onSearch }) => {
    const [searchFields, setSearchFields] = useState({
        applicationId: '',
        primary_email: '',
        first_name: '',
        primary_phone_number: '',
        province: '',
        city: ''
    });

    const handleInputChange = (e) => {
        setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
    };

    const handleSearch = () => {
        onSearch(searchFields);
    };

    return (
        <div className="row">
            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="applicationId" className="">Application ID</label>
                <input type="text" className="form-control" id="applicationId" placeholder="Application Id" name="applicationId" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="email" className="">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Email" name="primary_email" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="applicantName" className="">Applicant Name</label>
                <input type="text" className="form-control" id="applicantName" placeholder="Applicant Name" name="first_name" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="mobileNumber" className="">Mobile Number</label>
                <input type="tel" className="form-control" id="mobileNumber" placeholder="Mobile Number" name="primary_phone_number" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="province" className="">Province</label>
                <input type="text" className="form-control" id="province" placeholder="Province" name="province" onChange={handleInputChange} />
            </div>

            <div className="form-group mb-3 col-sm-12 col-md-6">
                <label htmlFor="city" className="">City</label>
                <input type="text" className="form-control" id="city" placeholder="City" name="city" onChange={handleInputChange} />
            </div>

            <div className="mb-3 col-sm-12 text-right">
                <button onClick={handleSearch} className="btn btn-primary m-1 text-white">Search</button>
            </div>

        </div>
    );
};

export default DashboardSearch