import React ,{useState} from "react";
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


function AkApplicantReport() {


    const [searchResults, setSearchResults] = useState(null);

    const handleSearch = async (searchFields) => {
        const token = localStorage.getItem("token");
      
        // Filter out empty fields and apply $containsi to all fields
        const queryObject = Object.entries(searchFields)
          .filter(([_, value]) => value !== "") // Keep only fields with non-empty values
          .reduce((acc, [key, value]) => {
            acc[`filters[${key}][$containsi]`] = value; // Use $containsi for partial, case-insensitive matches
            return acc;
          }, {});
      
        // Add the hardcoded status
        queryObject["filters[status][$eq]"] = "new";
        queryObject["filters[province][$eq]"] = "Azad Kashmir"; 
  
        // Include pagination and population
        const pageSize = 20;
        const currentPage = 1; // Default to the first page
        queryObject["pagination[pageSize]"] = pageSize;
        queryObject["pagination[page]"] = currentPage;
        queryObject["populate"] = "personal_statement,evaluations.users_permissions_user";
      
        const queryString = new URLSearchParams(queryObject).toString();
        console.log("Query String:", queryString);
      
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL_strapi}/applications?${queryString}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          if (response.ok) {
            const data = await response.json();
            console.log("Original Search Results:", data);
      
            // Pass normalized response to child component
            setSearchResults(data);
          } else {
            console.error(
              "Failed to fetch search results:",
              response.status,
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error during search:", error);
        }
      };


    return (
        <>
            <div className="app-admin-wrap layout-sidebar-large">
                <DashboardHeader />
                <AdminNav />
                <div className="main-content-wrap d-flex flex-column sidenav-open">
                    <h1>Azad Kashmir Applicant Report</h1>

                    <DashboardSearch onSearch={handleSearch} />


                    <DashboardTable title={'Azad Kashmir Applicant Report'} event={'ak'} type={"report"} province={'Azad Kashmir'} />

                </div>
            </div>
        </>
    );
}

export default AkApplicantReport;