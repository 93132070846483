import React, { useState } from "react";

import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


const RejectedApplications = () => {
    const [searchResults, setSearchResults] = useState(null);

    const handleSearch = async (searchFields) => {

        const token = localStorage.getItem("token");
      
        // Filter out empty fields and include the hardcoded status
        const queryObject = Object.entries(searchFields)
        .filter(([_, value]) => value !== "") // Keep only fields with non-empty values
        .reduce((acc, [key, value]) => {
          acc[`filters[${key}][$containsi]`] = value; // Use $containsi for partial, case-insensitive matches
          return acc;
        }, {});
      
        // Add the hardcoded status
        queryObject["filters[status][$eq]"] = "rejected";
      
        // Include pagination and population
        const pageSize = 20;
        const currentPage = 1; // Default to the first page
        queryObject["pagination[pageSize]"] = pageSize;
        queryObject["pagination[page]"] = currentPage;
        queryObject["populate"] = "personal_statement,evaluations.users_permissions_user";
      
        const queryString = new URLSearchParams(queryObject).toString();
        console.log("Query String:", queryString);
      
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL_strapi}/applications?${queryString}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          if (response.ok) {
            const data = await response.json();
            console.log("Original Search Results:", data);
      
            // Normalize the custom response to match the expected Strapi format
           
      
      
            // Pass normalized response to child component
            setSearchResults(data);
          } else {
            console.error(
              "Failed to fetch search results:",
              response.status,
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error during search:", error);
        }
      };

    return (

        <div className="app-admin-wrap layout-sidebar-large">
            <DashboardHeader />
            <AdminNav />
            <div className="main-content-wrap d-flex flex-column sidenav-open">
                <h1>Rejected Applications</h1>
                <DashboardSearch onSearch={handleSearch} />


                <DashboardTable title={'Rejected Applications'} event={'rejected'} status={'rejected'}   searchResults={searchResults} // Pass search results to the child component
/>

            </div>
        </div>
    )
}

export default RejectedApplications