import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

const DashboardTable = ({ event, title, type,status ,gender,province ,searchResults}) => {
    console.log("event",event)
    console.log("title",title)
    console.log("type",type)
    console.log("status",status)
    console.log("gender",gender)
    console.log("province",province)

    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const authUserInfo = JSON.parse(localStorage.getItem('auth_user_info')); // Get the stored JSON string and parse it to an object
    const userId = authUserInfo.id; // Access the 'id' property
    console.log("User ID:", userId);
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [realapplications, setRealApplications] = useState([]);

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);  // 0-based index for react-paginate

  const pageSize = 20;
    let apiCallURL = null;
    let api = null;

   
//     if (status) {
//        api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[status][$eq]=${status}&populate=personal_statement,evaluations.users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
//     } else if (gender) {
//       api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[gender][$eq]=${gender}&filters[status][$eq]=new&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
//     } else if (province) {
//       api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[province][$eq]=${province}&filters[status][$eq]=new&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
//     }


//   // Fetch Applications and Evaluations information
//   useEffect(() => {
//     const fetchApplicationsWithEvaluations = async () => {
//         try {
//             const response = await fetch(api, {
//                 method: 'GET',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 },
//             });

//             const applicationsData = await response.json();
//             const applications = applicationsData.data;
//             setTotalPages(Math.ceil(applicationsData.meta.pagination.total / pageSize));
            
//             // Process evaluations and calculate the sum of the relevant fields
//             const updatedApplications = applications.map(application => {
//                 const evaluations = application.attributes.evaluations?.data || [];

//                 const updatedEvaluations = evaluations.map(evaluation => {
//                     const attributes = evaluation.attributes;
//                     // Calculate the sum of desired fields
//                     const sum = (attributes.creativity || 0) +
//                                 (attributes.expression || 0) +
//                                 (attributes.future_ambition || 0) +
//                                 (attributes.past_ambition || 0) +
//                                 (attributes.relevance || 0);

//                     // Return evaluation with the sum
//                     return {
//                         ...evaluation,
//                         sum: sum, // Add the sum field to the evaluation
//                     };
//                 });

//                 // Update the application with the modified evaluations
//                 return {
//                     ...application,
//                     attributes: {
//                         ...application.attributes,
//                         evaluations: {
//                             ...application.attributes.evaluations,
//                             data: updatedEvaluations, // Set updated evaluations with the sum
//                         },
//                     },
//                 };
//             });
//          console.log("updatedApplications",updatedApplications)
//          console.log("updatedApplications",updatedApplications[0].attributes.evaluations?.data?.[0]?.sum)
//             setRealApplications(updatedApplications);
//         } catch (error) {
//             console.error('Error fetching applications and evaluations:', error);
//         }
//     };

//     if (api) {
//         fetchApplicationsWithEvaluations();
//     }
// }, [api, token]);

useEffect(() => {
  if (searchResults) {
    console.log("Search Results:", searchResults);
    
    // Update applications with search results
    const applications = searchResults.data;

    // Set total pages based on search results
    setTotalPages(searchResults.meta.pagination.pageCount);
    setCurrentPage(searchResults.meta.pagination.page - 1); // Adjust to 0-based index

    // Process evaluations and calculate the sum of the relevant fields
    const updatedApplications = applications.map((application) => {
      const evaluations = application.attributes.evaluations?.data || [];

      const updatedEvaluations = evaluations.map((evaluation) => {
        const attributes = evaluation.attributes;
        const sum =
          (attributes.creativity || 0) +
          (attributes.expression || 0) +
          (attributes.future_ambition || 0) +
          (attributes.past_ambition || 0) +
          (attributes.relevance || 0);

        return {
          ...evaluation,
          sum, // Add the sum field to the evaluation
          next_stage: attributes.next_stage, // Add the next_stage field to the evaluation

        };
      });

      return {
        ...application,
        attributes: {
          ...application.attributes,
          evaluations: {
            ...application.attributes.evaluations,
            data: updatedEvaluations,
          },
        },
      };
    });

    setRealApplications(updatedApplications);
  } else {
    // Build and fetch default data using the existing logic
    if (status) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[status][$eq]=${status}&populate=personal_statement,evaluations.users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage + 1}`;
    } else if (gender) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[gender][$eq]=${gender}&filters[status][$eq]=new&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${
        currentPage + 1
      }`;
    } else if (province) {
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[province][$eq]=${province}&filters[status][$eq]=new&populate[evaluations][populate]=users_permissions_user&pagination[pageSize]=${pageSize}&pagination[page]=${
        currentPage + 1
      }`;
    }

    // Fetch default applications with the existing API logic
    const fetchApplicationsWithEvaluations = async () => {
      try {
        const response = await fetch(api, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const applicationsData = await response.json();
          const applications = applicationsData.data;
          setTotalPages(applicationsData.meta.pagination.pageCount);
          setCurrentPage(applicationsData.meta.pagination.page - 1); // Adjust to 0-based index

          // Process evaluations and calculate the sum of the relevant fields
          const updatedApplications = applications.map((application) => {
            const evaluations = application.attributes.evaluations?.data || [];

            const updatedEvaluations = evaluations.map((evaluation) => {
              const attributes = evaluation.attributes;
              const sum =
                (attributes.creativity || 0) +
                (attributes.expression || 0) +
                (attributes.future_ambition || 0) +
                (attributes.past_ambition || 0) +
                (attributes.relevance || 0);

              return {
                ...evaluation,
                sum, // Add the sum field to the evaluation
                next_stage: attributes.next_stage, // Add the next_stage field to the evaluation

              };
            });

            return {
              ...application,
              attributes: {
                ...application.attributes,
                evaluations: {
                  ...application.attributes.evaluations,
                  data: updatedEvaluations,
                },
              },
            };
          });

          setRealApplications(updatedApplications);
          console.log("updatedApplications",updatedApplications)
        } else {
          console.error("Failed to fetch applications:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    fetchApplicationsWithEvaluations();
  }
}, [searchResults, status, gender, province, currentPage, token]);

    // Fetch Applications information and download as CSV


    // Function to convert JSON data to CSV
    const jsonToCsv = (data) => {
      if (!data || data.length === 0) return '';
      console.log("data test", data);
    
      // Extract main-level headers (excluding 'id' and 'status')
      const mainHeaders = Object.keys(data[0].attributes).filter(
        (key) => key !== 'id' && key !== 'evaluations' && key !== 'personal_statement' && key !== 'status'
      );
    
      // Rename 'reason' to 'reason_of_disability' in headers
      const updatedHeaders = mainHeaders.map((header) =>
        header === 'reason' ? 'reason_of_disability' : header
      );
    
      // Add evaluation headers (admin1, admin2, admin3)
      const evaluationHeaders = ['admin1', 'admin2', 'admin3'];
    
      // Add personal statement headers
      const personalStatementHeaders = ['personal_statement', 'skills', 'social_media', 'project_detail'];
    
      // Add recover to headers
      updatedHeaders.push('recover_status');
    
      // Combine all headers
      const headers = [...updatedHeaders, ...evaluationHeaders, ...personalStatementHeaders];
      const csvRows = [headers.map((header) => `"${header}"`).join(',')]; // Add headers row
    
      // Helper function to escape and wrap values in quotes
      const escapeValue = (value) => {
        if (value === null || value === undefined) return '""'; // Return empty quotes for null/undefined
        const escapedValue = value.toString().replace(/"/g, '""'); // Escape double quotes
        return `"${escapedValue}"`; // Wrap in quotes
      };
    
      // Process each application entry
      data.forEach((item) => {
        const attributes = item.attributes;
    
        // Extract main-level values, rename reason, modify nationality, and transform recover
        const mainValues = updatedHeaders.map((header) => {
          if (header === 'reason_of_disability') {
            return escapeValue(attributes['reason']); // Map 'reason' to 'reason_of_disability'
          }
          if (header === 'nationality') {
            const nationalityValue = attributes['nationality'] === 'true' ? 'Pakistan' : 'Other';
            return escapeValue(nationalityValue); // Map 'nationality' to 'Pakistan' or 'Other'
          }
          if (header === 'recover_status') {
            const recoverValue = attributes['recover'] === true ? 'Yes' : 'No';
            return escapeValue(recoverValue); // Map recover to Yes/No
          }
          return escapeValue(attributes[header]);
        });
    
        // Extract data for the three evaluations (or fill with empty if missing)
        const evaluationValues = [0, 1, 2].map((index) => {
          const evalData = attributes.evaluations?.data[index]?.attributes;
          const Data = attributes.evaluations?.data[index];
    
          const evaluatorName = evalData?.users_permissions_user?.data?.attributes?.username || '';
          const sum = Data?.sum || '';
    
          return escapeValue(evaluatorName ? `${evaluatorName}: ${sum}` : ''); // Format as "Name: Sum"
        });
    
        // Extract personal statement data
        const personalStatement = attributes.personal_statement?.data?.attributes || {};
        const personalValues = personalStatementHeaders.map((header) => escapeValue(personalStatement[header]));
    
        // Combine all values into a single row
        const row = [...mainValues, ...evaluationValues, ...personalValues];
        csvRows.push(row.join(',')); // Join the values with commas
      });
    
      return csvRows.join('\n'); // Join all rows with newlines
    };
    
    
    
    

    // Fetch Applications information and download as CSV
    const fetchDataAndDownload = async () => {
      setLoading(true);
    
      try {
        const token = localStorage.getItem("token");
        const pageSize = 100; // Fetch 100 records at a time
        let currentPage = 1;
        let allApplications = [];
        let totalPages = 1;
    
        // Determine the API endpoint based on filters
        let api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?populate=personal_statement,evaluations.users_permissions_user`;
    
        if (status) {
          api += `&filters[status][$eq]=${status}`;
        } else if (gender) {
          api += `&filters[gender][$eq]=${gender}&filters[status][$eq]=new`;
        } else if (province) {
          api += `&filters[province][$eq]=${province}&filters[status][$eq]=new`;
        }
    
        api += `&pagination[pageSize]=${pageSize}`;
    
        // Fetch all pages
        do {
          const paginatedApi = `${api}&pagination[page]=${currentPage}`; // Add page number
          console.log("Fetching from API:", paginatedApi);
    
          const response = await fetch(paginatedApi, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
    
          if (response.ok) {
            const responseData = await response.json();
            const applications = responseData.data;
    
            // Append the fetched applications to the main list
            allApplications = [...allApplications, ...applications];
    
            // Update pagination info
            totalPages = responseData.meta.pagination.pageCount;
            currentPage++; // Increment page for next fetch
          } else {
            console.error("Failed to fetch applications:", response.statusText);
            break;
          }
        } while (currentPage <= totalPages);
    
        // Process the applications to calculate sums and include additional fields
        const processedApplications = allApplications.map((application) => {
          const evaluations = application.attributes.evaluations?.data || [];
    
          const updatedEvaluations = evaluations.map((evaluation) => {
            const attributes = evaluation.attributes;
            const sum =
              (attributes.creativity || 0) +
              (attributes.expression || 0) +
              (attributes.future_ambition || 0) +
              (attributes.past_ambition || 0) +
              (attributes.relevance || 0);
    
            return {
              ...evaluation,
              sum, // Add the sum field to the evaluation
              next_stage: attributes.next_stage, // Add the next_stage field to the evaluation
            };
          });
    
          return {
            ...application,
            attributes: {
              ...application.attributes,
              evaluations: {
                ...application.attributes.evaluations,
                data: updatedEvaluations,
              },
            },
          };
        });
    
        // Convert processed data to CSV
        const csvData = jsonToCsv(processedApplications);
    
        // Create Blob from CSV data and download it
        const blob = new Blob([csvData], { type: "text/csv;charset=UTF-8" });
        FileSaver.saveAs(blob, `applicant_data.csv`);
    
        setLoading(false);
      } catch (error) {
        console.error("Error fetching and downloading data:", error);
        setLoading(false);
      }
    };
    



  
    const yes = async (id) => {
    
      if (!id || !token) {
        alert("Application ID or Token missing!");
        return;
      }
      let  outgoingData = {
        status:'final_stage'
      }           
     try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
          method: 'PUT', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure content-type is set to JSON
          },
          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
        });
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
        alert("Application moved to the final stage successfully.");

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error:', error);
      }

    




          
     
    };
    
  const no = async (id) => {
    
    if (!id || !token) {
      alert("Application ID or Token missing!");
      return;
    }
    let  outgoingData = {
      status:'rejected'
    }
      try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
            method: 'PUT', 
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json', // Ensure content-type is set to JSON
            },
            body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
          });
      
          if (!response.ok) {
            throw new Error('Failed to submit form');
          }
          alert("Application moved to the Rejected stage successfully.");

          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error('Error:', error);
        }



};
    
const Reopen = async (id) => {
  if (!id || !token) {
    alert("Application ID or Token missing!");
    return;
  }

  let outgoingData = {
    status: 'new',
    recover:true
  };

  try {
    // First API call: Update the application status
    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: outgoingData })
    });

    if (!response.ok) {
      throw new Error('Failed to submit form');
    }

    alert("Application moved to the new applications successfully.");

    const data = await response.json();
    console.log("Application update response:", data);

    // Second API call: Delete all evaluations related to this application
   

  } catch (error) {
    console.error('Error:', error);
  }
};


    // const allKeys = ['id', 'name', 'address', 'education', 'experience', 'nationlity', 'gender', 'email', 'contact', 'status'];
    const allKeys = ['id', 'name', 'email'];


    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>
            ) : (<div className="card">
                <div className="card-body">
                    <div className="simple-card-title d-flex justify-content-between align-items-center">
                        <h3 className="text-capitalize mb-1">{title}</h3>
                        <button className="btn-icon m-1 text-capitalize btn btn-primary text-white" onClick={fetchDataAndDownload}>
                            <span className="ul-btn__icon">
                                <i className="i-File-Excel"></i>
                            </span>
                            <span className="ul-btn__text">Export Data</span>
                        </button>
                    </div>
                    {realapplications && realapplications.length > 0 && (
 <table className="table table-bordered">
 <thead>
   <tr>
     {/* Other fixed column headers */}
     {['First Name', 'Primary Email', 'Address', 'Primary Phone Number'].map((key) => (
       <th key={key}>{key}</th>
     ))}
     {/* Dynamic headers for evaluations */}
     {['Ammar', 'Komal', 'Mustafa'].map((name) => (
       <th key={name}>{name}</th>
     ))}
     <th>Select</th>
   </tr>
 </thead>
 <tbody>
 {realapplications.map((application) => (
  <tr key={application.id}>
    {/* Mapping regular fields */}
    {['first_name', 'primary_email', 'address', 'primary_phone_number'].map((key) => (
      <td key={key}>
        {application.attributes[key] || 'N/A'}
      </td>
    ))}

    {/* Admin Columns (evaluations data under correct headings) */}
    {['ammar', 'komal', 'mustafa'].map((name, index) => {
  const evaluation = application.attributes.evaluations?.data.find(
    (evaluationObj) =>
      evaluationObj.attributes.users_permissions_user?.data?.attributes?.username?.toLowerCase() ===
      name.toLowerCase()
  );

  const username = evaluation?.attributes?.users_permissions_user?.data?.attributes?.username || 'N/A';
  const sum = evaluation?.sum !== undefined && evaluation?.sum !== null ? evaluation.sum : 'N/A';
  const nextStage = evaluation?.next_stage; // Retrieve next_stage
  const backgroundColor = nextStage === true ? 'lightgreen' : nextStage === false ? 'lightcoral' : 'transparent';

  return (
    <td key={name} style={{ backgroundColor }}>
      <strong>{username !== 'N/A' ? `${sum}` : 'N/A'}</strong>
    </td>
  );
    })}

    {/* Select Button */}
    <td>
      {status === 'approved' ? (
        <div>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => yes(application.id)}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => no(application.id)}
          >
            No
          </button>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => navigate(`/applications/view-application/${application.id}`)}
          >
            <span className="ul-btn__icon">
              <i className="i-Information"></i>
            </span>
            View
          </button>
        </div>
      ) : status === 'final_stage' ? (
        <div>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => navigate(`/applications/view-application/${application.id}`)}
          >
            <span className="ul-btn__icon">
              <i className="i-Information"></i>
            </span>
            View
          </button>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => no(application.id)}
          >
            Reject
          </button>
        </div>
      ) : status === 'rejected' ? (
        <div>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => Reopen(application.id)}
          >
            Recover
          </button>
        </div>
      ) : (
        <div>
          <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => navigate(`/applications/view-application/${application.id}`)}
          >
            <span className="ul-btn__icon">
              <i className="i-Information"></i>
            </span>
            View
          </button>
          {application.attributes.recover && (
            <span
              style={{
                display: "inline-block",
                backgroundColor: "red", // Light green background for success
                color: "#155724",          // Dark green text color
                border: "1px solid #c3e6cb", // Green border
                borderRadius: "20px",      // Rounded edges
                padding: "2px 10px",       // Padding inside the tag
                fontSize: "12px",          // Adjust font size
                fontWeight: "bold",        // Bold text
                textTransform: "uppercase" // Optional: Make text uppercase
              }}
              className="ml-2"
            >
              Recovered
            </span>
          )}
        </div>
      )}
    </td>
  </tr>
))}

 </tbody>
</table>

  )}




                </div>
            </div>
            )}
       <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setCurrentPage(selected)}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
        </div>
    );
};

export default DashboardTable;
