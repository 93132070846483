import { merge } from "lodash";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutSettings } from "app/redux/layout/layoutSlice";
import { useNavigate } from 'react-router-dom';

const DashboardHeader = () => {
    const dispatch = useDispatch();
    const { settings } = useSelector((state) => state.layout);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate('/signin');
    };

    const handleMenuClick = () => {
        dispatch(
            setLayoutSettings(
                merge({}, settings, {
                    layout1Settings: {
                        leftSidebar: {
                            open: settings.layout1Settings.leftSidebar.secondaryNavOpen
                                ? true
                                : !settings.layout1Settings.leftSidebar.open,
                            secondaryNavOpen: false,
                        },
                    },
                })
            )
        );
    };

    const toggleFullScreen = () => {
        if (document.fullscreenEnabled) {
            if (!document.fullscreen) document.documentElement.requestFullscreen();
            else document.exitFullscreen();
        }
    };

    const authUserInfo = JSON.parse(localStorage.getItem('auth_user_info'));
    let showName = '';
    if (authUserInfo) {
        if (authUserInfo.type === 1) {
            showName = authUserInfo.name;
        } else if (authUserInfo.type === 2) {
            showName = authUserInfo.fname + ' ' + authUserInfo.lname;
        }
    }

    return (
        <div className="main-header">
            <div className="logo">
                <img src="/assets/images/logo.png" alt="" style={{
                    width: "auto",
                    minWidth: "120%",
                    height: "60px",
                    marginLeft: "30px",
                    marginRight: "20px"
                }} />
            </div>

            {/* {authUserInfo && authUserInfo.type !== 2 && (
                <div className="menu-toggle mx-3" onClick={handleMenuClick}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )} */}

            <div style={{ margin: "auto" }}></div>

            {authUserInfo && (
                <div className="header-part-right">
                    <i
                        onClick={toggleFullScreen}
                        className="i-Full-Screen header-icon d-none d-sm-inline-block"
                        data-fullscreen
                    ></i>

                    <div className="user col px-3">
                        <Dropdown>
                            <Dropdown.Toggle as="span" className="toggle-hidden cursor-pointer">
                                <img
                                    src="/assets/images/faces/1.jpg"
                                    id="userDropdown"
                                    alt=""
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="dropdown-header">
                                    <i className="i-Lock-User me-1"></i> {showName}
                                </div>

                                <div
                                    className="dropdown-item cursor-pointer"
                                    onClick={handleLogout}
                                >
                                    Sign out
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardHeader;
