import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager, } from "react-notifications";

const ReferenceLetter = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [inputKey, setInputKey] = useState(Date.now());
    const [formData, setFormData] = useState({});
    const [referenceId, setReferenceId] = useState(null); // Holds the ID if data exists
    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer

    // useEffect(() => {
    //     console.log(file);
    // }, [file]);


    const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage


    useEffect(() => {
     const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
     if (disabledStatuses.includes(status)) {
       setIsDisabled(true);
     } else {
       setIsDisabled(false);
     }
   }, [status]); // Re-evaluate when the status changes

   

    useEffect(() => {
     

          // Fetch the latest campaign first
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${token}` // JWT token of the logged-in user
  }
})
  .then(response => response.json())
  .then(campaignResponse => {
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
    setLoading(false)
    if (!latestCampaign) {
      console.error('No campaign found');
      return; // Handle if no campaign exists
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch reference letters related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/reference-letters?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}&populate=reference_letter`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(reference_letters => {
        console.log('User with reference letters:', reference_letters);

        const referenceLetters = reference_letters.data; // Extract reference letters
        console.log('User with reference letters:', referenceLetters);

        if (referenceLetters.length > 0 && referenceLetters[0].attributes.reference_letter?.data?.attributes) {
          console.log('Reference Letter related to the user:', referenceLetters[0]);
        
          // Set form data with the reference letter details
          setFormData({
            name: referenceLetters[0].attributes.name,
            organization: referenceLetters[0].attributes.organization_name,
            designation: referenceLetters[0].attributes.designation,
            contact: referenceLetters[0].attributes.contact_number,
            email: referenceLetters[0].attributes.email,
            file: referenceLetters[0].attributes.reference_letter.data.attributes.url, // URL of the existing reference letter file
            filename: referenceLetters[0].attributes.reference_letter.data.attributes.name, // URL of the existing reference letter file

          });
        
          setReferenceId(referenceLetters[0].id); // Store ID for updating later
        } else {
          console.log('No reference letters related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user reference letters:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });

    }, []);

    const handleFileChange = (e) => {
        // console.log("I am working")
        if (e.target.files.length > 0) {
            if (e.target.files[0].size <= 5000000 && e.target.files[0].type === 'application/pdf') {
                setFile(e.target.files[0]);
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
                setInputKey(Date.now()); // Reset the file input field
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };
    const validateForm = () => {
      const newErrors = {};
    
      // Validate Name
      if (!formData.name || formData.name.trim() === "") {
        newErrors.name = "Name is required.";
      }
    
      // Validate Organization
      if (!formData.organization || formData.organization.trim() === "") {
        newErrors.organization = "Organization is required.";
      }
    
      // Validate Designation
      if (!formData.designation || formData.designation.trim() === "") {
        newErrors.designation = "Designation is required.";
      }
    
      // Validate Contact Number
      const contactRegex = /^\d{10,15}$/; // Adjust for your required number format
      if (!formData.contact) {
        newErrors.contact = "Contact Number is required.";
      } else if (!contactRegex.test(formData.contact)) {
        newErrors.contact = "Please enter a valid contact number (10-15 digits).";
      }
    
      // Validate Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!formData.email) {
        newErrors.email = "Email is required.";
      } else if (!emailRegex.test(formData.email)) {
        newErrors.email = "Please enter a valid email address.";
      }
    
      // Validate File (Reference Letter)
      if (!file && !formData.file) {
        newErrors.file = "Reference letter is required.";
      }
    
      setErrors(newErrors);
    
      // Return true if no errors
      return Object.keys(newErrors).length === 0;
    };
    
const handleChange = (e) => {
  const { name, value } = e.target;

  let newErrors = { ...errors };

  if (name === "email") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      newErrors.email = "Please enter a valid email address.";
    } else {
      delete newErrors.email;
    }
  }

  if (name === "contact") {
    const contactRegex = /^\d{10,15}$/;
    if (!contactRegex.test(value)) {
      newErrors.contact = "Please enter a valid contact number (10-15 digits).";
    } else {
      delete newErrors.contact;
    }
  }

  setErrors(newErrors);
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Validate the form
      if (!validateForm()) {
        return; // Stop here if validation fails
      }
    
      const subFormData = new FormData();
      subFormData.append('data', JSON.stringify({
        name: formData.name,
        organization_name: formData.organization,
        designation: formData.designation,
        contact_number: formData.contact,
        email: formData.email,
      }));
    
  
      subFormData.append('application_id', applicationId);
    
      try {
        let response;
    
        if (referenceId) {
          if (file) {
            console.log("file",file)
            subFormData.append('files.file', file); // Append the file if a new one is selected
          }
          // Update the existing reference letter, including the file if a new one is chosen
          response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/reference-letters/${referenceId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: subFormData,
          });
        } else {
          if (file) {
            console.log("file",file)
            subFormData.append('files.file', file); // Append the file if a new one is selected
          }
          // Create a new reference letter if no existing ID is present
          response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/reference-letters`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: subFormData,
          });
        }
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
    
        const data = await response.json();
        if (data?.data?.id) {
          localStorage.setItem("Refrence_letter", data.data.id);
        } else {
          localStorage.setItem("Refrence_letter", data.id);
        }
    
        console.log('Form submitted successfully:', data);
    
        // Reset file input and navigate to the next page
        setInputKey(Date.now());
        navigate('/application/funding-options');
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
    
    
    

    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">
                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                              <form onSubmit={handleSubmit}>
                              <div className="card">
                                <div className="card-body">
                                  <h4>Reference Letter</h4>
                                  <div className="row">
                                    <div className="form-group mb-3 col-12">
                                      <h6 className="m-0">{formData.refFrom}</h6>
                                    </div>
                            
                                    {/* Name */}
                                    <div className="form-group mb-3 col-12">
                                      <label htmlFor="name">Name <span className="text-danger">*</span></label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"  // Add the name attribute
                                        placeholder="Enter Name"
                                        value={formData.name || ""}  // Bind to formData state
                                        onChange={handleChange}  // Call handleChange on input change
                                        disabled={isDisabled}

                                      />
                                      {errors.name && <small className="text-danger">{errors.name}</small>}
                                    </div>
                            
                                    {/* Organization */}
                                    <div className="form-group mb-3 col-12">
                                      <label htmlFor="organization">Organization <span className="text-danger">*</span></label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="organization"
                                        name="organization"  // Add the name attribute
                                        placeholder="Enter Organization"
                                        value={formData.organization || ""}  // Bind to formData state
                                        onChange={handleChange}  // Call handleChange on input change
                                        disabled={isDisabled}

                                      />
                                      {errors.organization && <small className="text-danger">{errors.organization}</small>}
                                    </div>
                            
                                    {/* Designation */}
                                    <div className="form-group mb-3 col-12">
                                      <label htmlFor="designation">Designation <span className="text-danger">*</span></label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="designation"
                                        name="designation"  // Add the name attribute
                                        placeholder="Enter Designation"
                                        value={formData.designation || ""}  // Bind to formData state
                                        onChange={handleChange}  // Call handleChange on input change
                                        disabled={isDisabled}

                                      />
                                      {errors.designation && <small className="text-danger">{errors.designation}</small>}
                                    </div>
                            
                                    {/* Contact Number */}
                                    <div className="form-group mb-3 col-12">
                                      <label htmlFor="contact">Contact Number <span className="text-danger">*</span></label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="contact"
                                        name="contact"  // Add the name attribute
                                        placeholder="Enter Contact Number"
                                        value={formData.contact || ""}  // Bind to formData state
                                        onChange={handleChange}  // Call handleChange on input change
                                        disabled={isDisabled}

                                      />
                                      {errors.contact && <small className="text-danger">{errors.contact}</small>}
                                    </div>
                            
                                    {/* Email */}
                                    <div className="form-group mb-3 col-12">
                                      <label htmlFor="email">Email <span className="text-danger">*</span></label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"  // Add the name attribute
                                        placeholder="Enter Email"
                                        value={formData.email || ""}  // Bind to formData state
                                        onChange={handleChange}  // Call handleChange on input change
                                        disabled={isDisabled}

                                      />
                                      {errors.email && <small className="text-danger">{errors.email}</small>}
                                    </div>
                            
                                    {/* File Upload */}
                                    <label htmlFor="file">Upload Reference Letter <span className="text-danger">*</span></label>
                                    {/* <div className="input-group mb-3 col-12">
                                      <input
                                        type="file"
                                        className="form-control"
                                        id="file"
                                        onChange={handleFileChange}
                                        key={inputKey}
                                        accept=".pdf"
                                        disabled={isDisabled}

                                      />
                                      <label className="input-group-text" htmlFor="file">
                                      {referenceId ? "Update" : "Upload"}
                                        </label>
                                    </div> */}



                                

                                   


                                    {!referenceId &&
                                          <div className="input-group mb-3 col-12">
                                              <input
                                                  type="file"
                                                  className="form-control"
                                                  id="file"
                                                  onChange={handleFileChange}
                                                  key={inputKey}
                                                  accept=".pdf"
                                                  disabled={isDisabled}
                                              />
                                            <label className="input-group-text" htmlFor="file">
                                                  {referenceId ? "Update" : "Upload"}
                                              </label>          
                                                            </div>
                                     }
                                              
                                          {referenceId && 
                                                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '5px' }}>
                                                      {file ? (
                                                          // Show file name from selected file
                                                          <span style={{ padding: '10px', color: '#6c757d', flex: '1' }}>{file.name}</span>
                                                        ) : (
                                                          // Show saved file name if no new file is selected
                                                          <span style={{ padding: '10px', color: '#6c757d', flex: '1' }}>{formData?.filename || "No file chosen"}</span>
                                                        )}
                                                                                                            
                                                    

                                                        {/* Conditionally display the file input */}
                                                        
                                                          <input
                                                            type="file"
                                                            className="form-control"
                                                            id="file"
                                                            onChange={handleFileChange}
                                                            key={inputKey}
                                                            accept=".pdf"
                                                            disabled={isDisabled}
                                                            style={{ display: 'none' }}

                                                          />
                                                        

                                                        {/* Upload/Update button */}
                                                        <label className="input-group-text" htmlFor="file">
                                                  {referenceId ? "Update" : "Upload"}
                                              </label>   
                                                      </div>
                                         }




                                        {errors.file && <small className="text-danger">{errors.file}</small>}    



                                    {/* View Uploaded File Button */}
                                    <div className="input-group mb-3 col-12">
                                      {formData.file && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary m-1"
                                          onClick={() => window.open(`${formData.file}`, '_blank')}
                                        >
                                          View Current Reference Letter
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                            
                                <div className="card-footer">
                                  <div className="mc-footer">
                                    <div className="row text-center">
                                      <div className="col-lg-12">
                                        <button type="submit" className="btn btn-primary text-white m-1"                           disabled={isDisabled}
>Save & Next</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>

    )

};

export default ReferenceLetter;
