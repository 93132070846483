import React from "react";
import ReactEcharts from "echarts-for-react";
import { echartOptions } from "@gull";

const LineChart3 = ({ height, data }) => {
  console.log("chart data ", data);

  // Extract dates (xAxis) and counts (yAxis)
  const xAxisData = Object.keys(data).reverse(); // Reverse dates
  const yAxisData = Object.values(data).reverse(); // Reverse counts

  const option = {
    ...echartOptions.lineSplitNoAxis,
    grid: {
      top: 15,
      left: 15,
      right: 15,
      bottom: 0,
    },
    xAxis: {
      type: "category",
      data: xAxisData, // Use dynamic dates
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: yAxisData, // Use dynamic counts
        lineStyle: {
          color: "rgba(112, 191, 106, 0.8)",
          width: 3,
          ...echartOptions.lineShadow,
        },
        label: { show: true, color: "#212121" },
        type: "line",
        smooth: true,
        itemStyle: {
          borderColor: "rgba(102, 51, 153, 1)",
        },
      },
    ],
  };

  return <ReactEcharts style={{ height: height }} option={option} />;
};

export default LineChart3;
