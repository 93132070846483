import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from 'axios';
import DashboardHeader from "./DashboardHeader";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import { useEffect } from "react";


const PrivacyPolicyStatement = () => {
  const [errors, setErrors] = useState([]);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [awailable, setAwailable] = useState(false);
  const [id, setId] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false); // Track modal visibility

  const [resume, setResume] = useState({});
  const [personalStatement, setPersonalStatement] = useState({});
  const [fields, setFields] = useState({});
  const [education, setEducation] = useState([]);
  const [referenceLetter, setReferenceLetter] = useState({});
  const [employment, setEmployment] = useState([]);
  const [referenceletterdata, setReferenceLetterData] = useState([]);

  const handleShowModal = () => {
    fetchData()

    setShowModal(true);
  }
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  console.log("data needed",auth)
  const applicantID = auth ? auth.id : null;
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
 console.log("token",token)
 const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer
 const handleConfirmSubmit = () => {
  setShowModal(false); // Close the modal
  submit(); // Proceed to submit the form
};


const fetchData = async () => {
  try {

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationId}?populate[academic_detailss]=*&populate[personal_statement]=*&populate[privacy_policy_statement]=*&populate[professional_detailss]=*&populate[reference_letter][populate][reference_letter]=*&populate[resume][populate][resume]=*`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
      );

      const data = await response.json();
      const attributes = data.data?.attributes || {};

      setResume({
          url: attributes.resume?.data?.attributes?.resume?.data?.attributes?.url || ""
      });

      setPersonalStatement(attributes.personal_statement?.data?.attributes || {});
      setFields(attributes);
      setEducation(attributes.academic_detailss?.data || []);
      setReferenceLetter({
          url: attributes.reference_letter?.data?.attributes?.reference_letter?.data?.attributes?.url || ""
      });
      setEmployment(attributes.professional_detailss?.data || []);
      setReferenceLetterData(attributes?.reference_letter?.data?.attributes || [])

      console.log("attributes.reference_letter?.data?.attributes?.reference_letter?.data?.attributes?.url ",attributes.reference_letter?.data?.attributes?.reference_letter?.data?.attributes?.url)
  } catch (error) {
      console.error('Error fetching personal information data:', error);
  }
};



  const validate = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/submit`, {
      headers: {
        "accept": "*/*",
        "ApplicantId": applicantID,
        "Event": "validate",
        "SessionKey": "123"
      }
    });

    const errorProblems = response.data.data.problems.filter(problem => problem.includes('ERROR'));
    if (errorProblems.length > 0) {
      setErrors(errorProblems);
      return false;
    }

    return true;
  };
  const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage

  useEffect(() => {
    
    const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
    if (disabledStatuses.includes(status)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]); // Re-evaluate when the status changes


  useEffect(() => {
    const fetchData = async () => {
     // Fetch the latest campaign first
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${token}`, // JWT token of the logged-in user
  }
})
  .then(response => response.json())
  .then(campaignResponse => {
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // Handle if no campaign exists
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch privacy policy statements related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/privacy-policy-statements?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(privacy_policy_statements => {
        const privacy_policy_statement = privacy_policy_statements.data[0]; // Extract the first privacy policy statement

        if (privacy_policy_statement) {
          setCheckbox1(privacy_policy_statement.attributes.read_all);
          setCheckbox2(privacy_policy_statement.attributes.available);
          setAwailable(true);
          setId(privacy_policy_statement.id); // Save the ID for further operations
        } else {
          console.log('No privacy policy statements related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching privacy policy statements:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });

    };
    fetchData();
  }, [token]);
  
  // Track changes in checkbox1 and checkbox2
  useEffect(() => {
    console.log("checkbox1 updated:", checkbox1);
    console.log("checkbox2 updated:", checkbox2);
  }, [checkbox1, checkbox2]); // This useEffect will run every time checkbox1 or checkbox2 is updated
  


  const submit = async () => {
    //Submit Form Api

    console.log("checkbox1",checkbox1)
    console.log("checkbox2",checkbox2)
    let data1  = {
      read_all:true,
      available:true
    }
 if(awailable){
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/privacy-policy-statements/${id}`, {
      method: 'PUT', 
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // Ensure content-type is set to JSON
      },
      body: JSON.stringify({ data: data1 }) // Send the data wrapped in a 'data' object
    });

    if (!response.ok) {
      throw new Error('Failed to submit form');
    }

    const data = await response.json();
    console.log(data);
    navigate('/application/status');
  } catch (error) {
    console.error('Error:', error);
  }
 }else {

 
  try {
    // 1. First API Call: Submit Privacy Policy Statement
    const response1 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/privacy-policy-statements`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json', // Ensure content-type is set to JSON
      },
      body: JSON.stringify({ data: { ...data1, applicationId } }), // Wrap data in 'data' object
    });
  
    if (!response1.ok) {
      throw new Error('Failed to submit privacy policy statement');
    }
  
    const data1Response = await response1.json();
    console.log('Privacy Policy Response:', data1Response);
    let outgoingData = {
      status:'new'
    }
    // 2. Second API Call: Update Application Status
    const response2 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: outgoingData }), // Wrap outgoingData in 'data' object
    });
  
    if (!response2.ok) {
      throw new Error('Failed to update application status');
    }
  
    const data2Response = await response2.json();
    console.log('Application Status Update Response:', data2Response);
    localStorage.setItem("application_status", "new");

    // 3. Third API Call: Send Thank You Email
    const response3 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/email/thankyouemail`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.email1, // Pass applicant email
        applicantName: auth.fname, // Pass applicant name
      }),
    });

    if (!response3.ok) {
      throw new Error('Failed to send thank-you email');
    }

    const emailResponse = await response3.json();
    console.log('Thank You Email Response:', emailResponse);

    // Navigate to status page after all requests succeed
    navigate('/application/status');


    // Navigate to status page after both requests succeed
    navigate('/application/status');
  } catch (error) {
    console.error('Error:', error);
  }
  
  }
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
              {errors.length > 0 && (
                <div>
                  <h4>Information Missing</h4>
                  <ul className="list-group">
                    {errors.map((error, index) => (
                      <li className="list-group-item list-group-item-danger" key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}



            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              <form>
                <div className="card ">
                  <div className="card-body">
                    <h4>Privacy Policy Statement</h4>
                    <p className="w-100">
                      Renewables First (RF) hereinafter referred to as "we," "us," or "our," is dedicated to safeguarding the privacy of your personal information. This Privacy Policy pertains to the collection, use, disclosure, and protection of your data when you use our online application platform. By using our services or submitting information to us, you consent to the practices described in this policy.
                    </p>

                    <ol className="p-0 privacy-formatting">
                      <li className="list-group-item">
                        <strong>1. Information We Collect</strong>
                        <div>
                          <strong>Application Data: </strong>We collect personal information you provide during the application process, which may include your name, email address, postal address, phone number, educational history, work experience, and other relevant details.
                        </div>
                        <div>
                          <strong>Usage Information:</strong> We gather data about how you use our online application platform, including IP address, browser type, operating system, pages visited, and time spent on the platform.

                        </div>
                        <div>
                          <strong>Cookies:</strong> We use cookies and similar technologies to track user interactions with our platform. You can manage cookie preferences using your browser settings.
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>2. How We Use Your Information</strong>
                        <div>
                          We use the information you provide for the following purposes
                          <ol>
                            <li>To process and evaluate your application.</li>
                            <li>To communicate with you regarding your application status</li>
                            <li>To analyze and enhance the performance of our online application platform.</li>
                            <li>To meet legal and regulatory requirements.</li>
                          </ol>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>3. Data Sharing and Disclosure</strong>
                        <div>
                          We do not share your personal information with third parties, except in the following cases:
                          <ol>
                            <li>With your explicit consent.</li>
                            <li>When required by law or to protect our legal rights.</li>
                          </ol>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>4. Data Security</strong>
                        <div>
                          We employ industry-standard security measures to protect your data from unauthorized access or disclosure. However, no method of data transmission over the Internet or electronic storage is completely secure. We cannot guarantee the absolute security of your data.
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>5. Your Choices</strong>
                        <div>
                          You have the right to access, update, or delete your application data. To exercise these rights, please contact us using the information below.

                        </div>
                        Certification: I certify that I completed this application myself, that the information given in this application is complete and accurate, and that I have carefully read and understand all notes and disclaimers provided therein. I understand that Renewables First reserves the right to verify all the information listed in the application. I understand that giving false or misleading information in the application will result in exclusion from the competition or immediate dismissal from the Margalla School on Energy and Climate Program.

                      </li>
                    </ol>


                    <p className="mb-4">Also, I acknowledge that I am aware of the following requirements that I must observe if I am selected for the program:</p>

                    <div className="ul-widget-app__task-list">
                      <label className="checkbox checkbox-primary">
                        <input 
                          type="checkbox" 
                          checked={checkbox1} // Bind the checked attribute to the state
                          onChange={e => setCheckbox1(e.target.checked)} 
                          disabled={isDisabled}
                          // Update state on change
                        />
                        <strong>
                          <span>
                            Must abide by all program rules and regulations. 
                            <span className="text-danger">*</span>
                          </span>
                        </strong>
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="ul-widget-app__task-list mb-4">
                      <label className="checkbox checkbox-primary">
                        <input type="checkbox" onChange={e => setCheckbox2(e.target.checked)}   checked={checkbox2}   disabled={isDisabled}/>
                        <strong>
                          I will be available for the complete duration of the training in February 2025. <span className="text-danger">*</span>
                        </strong>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="mc-footer">
                      <div className="row text-center">
                        <div className="col-lg-12 ">
                          <button type="button" className="btn btn-primary text-white m-1" onClick={handleShowModal}  disabled={!checkbox1 || !checkbox2 || isDisabled }>
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div >
        </div>
      </div>
      {showModal && (
        <div
    className="modal"
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px 0', // Adds space at the top and bottom of the entire modal
    }}
  >
         <div
      className="modal-content"
      style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '80%',
        maxWidth: '500px',
        maxHeight: 'calc(100vh - 120px)', // Accounts for padding and fixed height
        overflowY: 'auto',
        textAlign: 'center',
      }}
    >
            <h2>PREVIEW</h2>
            
            {/* Personal Information */}
            <h3>Personal Information</h3>
            <p><strong>First Name:</strong> {fields.first_name}</p>
            <p><strong>Last Name: </strong> {fields.last_name}</p>
            <p><strong>Gender:</strong>  {fields.gender}</p>
            <p><strong>Date of Birth:</strong>  {fields.date_of_birth}</p>
            <p><strong>Disability:</strong>  {fields.disability}</p>
            <p><strong>Nationality: </strong> {fields.nationality}</p>
            <p><strong>Province:</strong>  {fields.province}</p>
            <p><strong>City: </strong> {fields.city}</p>
            <p><strong>Address:</strong>  {fields.address}</p>
            <p><strong>Primary Phone Number: </strong> {fields.primary_phone_number}</p>
            <p><strong>Primary Email: </strong> {fields.primary_email}</p>

            {/* Academic Details */}
            <h3>Academic Details</h3>
            {education.map((academic, index) => (
                <div key={index}>
                    <p><strong>Degree:</strong> {academic.attributes.degree}</p>
                    <p><strong>Institute:</strong> {academic.attributes.institute}</p>
                    <p><strong>Field of Study:</strong> {academic.attributes.field_of_study} </p>
                    <p><strong>Start Year:</strong> {academic.attributes.start_year}</p>
                    <p><strong>End Year:</strong> {academic.attributes.end_year}</p>
                </div>
            ))}
              <h3>Professional Details</h3>
            {employment.map((academic, index) => (
                <div key={index}>
                    <p><strong>Job Title:</strong> {academic.attributes.job_title}</p>
                    <p><strong>Organization Name: </strong>{academic.attributes.organization_name}</p>
                    <p><strong>Type of Job:</strong> {academic.attributes.type_of_job}</p>
                    <p><strong>Start Year:</strong> {academic.attributes.start_year}</p>
                    <p><strong>End Year:</strong> {academic.attributes.end_year}</p>
                </div>
            ))}

            {/* Personal Statement */}
            <h3>Personal Statement</h3>
            <p><strong>Statement: </strong>  <br />{personalStatement.personal_statement}</p>
            <p><strong>Skills:</strong>  <br />{personalStatement.skills}</p>
            <p><strong>Project Detail:</strong> <br /> {personalStatement.project_detail}</p>
            <p><strong>Social Media: </strong>  {personalStatement.social_media}</p>

            {/* Reference Letter */}
            <h3>Reference Letter</h3>
            <p><strong>Name:</strong> {referenceletterdata && referenceletterdata?.name ? referenceletterdata?.name : 'N/A'}</p>
            <p><strong>Organization Name:</strong>{referenceletterdata && referenceletterdata?.organization_name ? referenceletterdata?.organization_name : 'N/A'}</p>
            <p><strong>Designation:</strong>{referenceletterdata && referenceletterdata?.designation ? referenceletterdata?.designation : 'N/A'}</p>
            <p><strong>Contact Number:</strong> {referenceletterdata && referenceletterdata?.contact_number ? referenceletterdata?.contact_number : 'N/A'}</p>
            <p><strong>Email:</strong>{referenceletterdata && referenceletterdata?.email ? referenceletterdata?.email : 'N/A'}</p>
            <p>
                <a href={`${referenceLetter?.url}`} target="_blank" rel="noopener noreferrer">
                    View Reference Letter
                </a>
            </p>

            {/* Resume */}
            <h3>Resume</h3>
            <p>
                <a href={`${resume?.url}`} target="_blank" rel="noopener noreferrer">
                    View Resume
                </a>
            </p>

            {/* Submit and Cancel Buttons */}
            <button className="btn btn-primary m-1" onClick={handleConfirmSubmit}>Confirm and Submit</button>
            <button className="btn btn-secondary m-1" onClick={() => setShowModal(false)}>Cancel</button>
        </div>
    </div>
)}
      <NotificationContainer />
    </div >
  );
};

export default PrivacyPolicyStatement;
